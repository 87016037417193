export const Constants = {
    FireType_ProgramFire: 'programfire',
    FireType_NoteworthyFire: 'noteworthyfire',
    FireType_MonitoredFire: 'monitoredfire',
    FireType_NonDispatchedFire: 'nondispatchedfire',
    FireType_DemobilizedFire: 'demobilizedfire',

    Layer_RegionalDanger: 'regionaldanger',
    Layer_Smoke: 'smoke',
    Layer_WeatherHazards: 'weatherhazards',

    Logo_Path: 'client-logos',
    AppRoles: {
        allRead: ['Admin', 'Manager', 'Contributor', 'Reader'],
        write: ['Admin', 'Manager', 'Contributor'],
    },
    Site: {
        Internal: 'Internal',
        External: 'External'
    },

    Admin: {
        PageName: {
            InviteUser: 'invite-user',
            AddUser: 'add-user',
            EditUser: 'edit-user',
            AddClient: 'add-client',
            EditClient: 'edit-client'
        }
    },

    Cookie_Sitmap: 'WDS.Sitmap',
    Cookie_Firemap: 'WDS.Firemap',
    Cookie_SmokeCheck: 'WDS.SmokeCheck',
    Cookie_FireOverview: 'WDS.FireOverview',
    Cookie_ResourceStatus: 'WDS.ResourceStatus',
    Cookie_Consent: 'WDS.CookieConsent',

    States: ['AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN',
        'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VT', 'WA',
        'WI', 'WV', 'WY'],

    StateNames: {
        'AL': "Alabama",
        'AK': "Alaska",
        'AZ': "Arizona",
        'AR': "Arkansas",
        'CA': "California",
        'CO': "Colorado",
        'CT': "Connecticut",
        'DC': "Washington DC",
        'DE': "Delaware",
        'FL': "Florida",
        'GA': "Georgia",
        'HI': "Hawaii",
        'ID': "Idaho",
        'IL': "Illinois",
        'IN': "Indiana",
        'IA': "Iowa",
        'KS': "Kansas",
        'KY': "Kentucky",
        'LA': "Louisiana",
        'ME': "Maine",
        'MD': "Maryland",
        'MA': "Massachusetts",
        'MI': "Michigan",
        'MN': "Minnesota",
        'MS': "Mississippi",
        'MO': "Missouri",
        'MT': "Montana",
        'NE': "Nebraska",
        'NV': "Nevada",
        'NH': "New Hampshire",
        'NJ': "New Jersey",
        'NM': "New Mexico",
        'NY': "New York",
        'NC': "North Carolina",
        'ND': "North Dakota",
        'OH': "Ohio",
        'OK': "Oklahoma",
        'OR': "Oregon",
        'PA': "Pennsylvania",
        'RI': "Rhode Island",
        'SC': "South Carolina",
        'SD': "South Dakota",
        'TN': "Tennessee",
        'TX': "Texas",
        'UT': "Utah",
        'VT': "Vermont",
        'VA': "Virginia",
        'WA': "Washington",
        'WV': "West Virginia",
        'WI': "Wisconsin",
        'WY': "Wyoming",
        'NoCal': "North California",
        'SoCal': "South California",
    } as any,

    CAProvinces: ['AB','BC','MB','NB','NL','NS','ON','PE','QC','SK'],

    ProvinceNames: {
        'AB': "Alberta",
        'BC': "British Columbia",
        'MB': "Manitoba",  
        'NB': "New Brunswick",
        'NL': "Newfoundland and Labrador",
        'NS': "New Brunswick",
        'ON': "Ontario",
        'PE': "Prince Edward Island",
        'QC': "Quebec",
        'SK': "Saskatchewan",
    } as any,

    FuelTypes: ['Timber', 'Brush', 'Grass', 'Slash', 'Pine Timber Litter', 'Freshwater Marsh', 'Unknown'],

    AlertMiles: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],

    Enrolled: "enrolled",
    NotEnrolled: "not enrolled",
    WDSLob: {
        Residential: 1,
        Commercial: 2,
        Agricultural: 3,
        MGU: 4,
    },
    TrackingType: {
        Manual: "0",
        ActiveTracking: "1",
        InactiveTracking: "2"
    },
    FeatureCategory: {
        BranchBreak: "Branch Break",
        FireOrigin: "Fire Origin",
        DivisionBreak: "Division Break",
        ZoneBreak: "Zone Break",
        IncidentCommandPost: "Incident Command Post",
        HotSpotSpotFire: "Hot Spot - Spot Fire",
        FireStation: "Fire Station",
        SafetyZone: "Safety Zone",
        WaterSource: "Water Source",

        IRIsolatedHeatSource: "IR Isolated Heat Source",
        PossibleIRHeatSource: "Possible IR Heat Source",
        PlannedDozerLine: "Planned Dozer Line",
        PlannedHandLine: "Planned Hand Line",
        PlannedPlowLine: "Planned Plow Line",
        FireEdge: "Fire Edge",
        CompletedDozerLine: "Completed Dozer Line",
        CompletedHandLine: "Completed Hand Line",
        CompletedPlowLine: "Completed Plow Line",
        PlannedBurnout: "Planned Burnout",
        PlannedFuelBreak: "Planned Fuel Break",
        PlannedRoadasLine: "Planned Road as Line",
        ContainedLine: "Contained Line",
    },
    ErrorMessage: {
        Required: "Required",
        AllRequiredBeforeSave: "Please complete all required fields.",
        DmsRequired: "Please make sure Degrees, Minutes and Seconds are not empty.",
        DdmRequired: "Please make sure Degrees and Minutes are not empty.",
        DecimalLat: "Lat must be decimal.",
        DecimalLong: "Long must be decimal.",
        rangLat: "Please make sure Lat is (10 to 90).",
        rangLong: "Please make sure Long is (-180 to -10).",
        rangMinutes: "Please make sure Minutes is (0 to 59).",
        rangMinutesDecimal: "Please make sure Minutes is (0 to 59.9999).",
        rangSeconds: "Please make sure Seconds is (0 to 59.9999).",
        emailFormat: "Please make sure Email format is correct.",
        zipFormat: "Please make sure Zip format is correct.",
        phoneFormat: "Please make sure Phone format is correct.",
        VINOccupied: "VIN Occupied"
    },

    // Fire overview
    FireOverviewTabsText: {
        FireInformation: "Fire Information",
        PropertyList: "Property List",
        Unmatched: "Unmatched",
        PreviousFireInformation: "Previous Fire Information",
        SeeAllVisits: "See All Visits"
    },

    PreDefinedLists: [
        { Id: 0, Name: "Please choose..." },
        { Id: 1, Name: "Potentially Threatened" },
        { Id: 2, Name: "Unable to Locate or Access" }],

    CurrentlyThreatened: ["Please choose...", "Yes", "No"],

    LastPropertyStatus: ["None", "Undamaged", "Damaged", "Lost", "Saved", "No Access", "Unknown"],

    PropertyType: ["Residential", "Commercial", "Agricultural", "MGU"],

    PropertyOtherColumns: [
        {Text: "Address Line 2", Field: "address2"},
        { Text: "Enrolled", Field: "enrolled" },
        { Text: "Current Status", Field: "lastPropertyStatus" },
        { Text: "Date of Status", Field: "dateAction" },
        { Text: "Zip Code", Field: "zipCode" },
        { Text: "Building Count", Field: "buildingCount" },
        { Text: "Calls", Field: "callCount" },
        { Text: "Exposure", Field: "coverageAAmt" },
        { Text: "Producer", Field: "producer" },
        { Text: "Line of Business", Field: "lineOfBusiness" },
        { Text: "Agency Name", Field: "agencyName" },
        { Text: "Agency Code", Field: "agencyCode" },
        { Text: "Agency Phone Number", Field: "agencyPhoneNumber" },
        { Text: "Location", Field: "location" },
    ],
    PropertyOtherFireMapColumns: [
        { Text: "Enrolled", Field: "enrolled" },
        { Text: "Zip Code", Field: "zip" },
        { Text: "Building Count", Field: "numberOfBuilding" },
        { Text: "Calls", Field: "callCount" },
        { Text: "Exposure", Field: "exposure" },
        { Text: "Producer", Field: "producer" },
        { Text: "Line of Business", Field: "wdsLob" },
        { Text: "Agency Name", Field: "agencyName" },
        { Text: "Agency Code", Field: "agencyCode" },
        { Text: "Agency Phone Number", Field: "agencyPhone" },
        { Text: "Location", Field: "location" },
    ],
    EmailRegExp:
        /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,

    AGKey: `Using_this_{AG_Grid}_Enterprise_key_{AG-065062}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Wildfire_Defense_Systems}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{NGP}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{NGP}_need_to_be_licensed___{NGP}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{14_October_2025}____[v3]_[01]_MTc2MDM5NjQwMDAwMA==918c6b239ace86c533c619f72960c010`,

    Colors: ['#F2B643', '#334051', '#A6A6A6', '#F8DBA1', '#999FA8', '#D3D3D3', '#FCF0D9', '#D6D9DC', '#EDEDED'],
    NoticeTypes: [
        { RecommendedAction: 'No Immediate Threat', WDSStatus: 2, WDSAction: 'Based on current conditions the fire will likely not affect the enrolled properties. Program resources will continue to monitor containment and will issue updated notices as warranted.', ThreatPerimeterRequired: false, Order: 1 },
        { RecommendedAction: 'Potential Threat', WDSStatus: 2, WDSAction: 'Local resources will be in the best position to provide initial attack and structure protection. Program resources will monitor the fire for any changes in threat that would adversely impact the insured properties and respond accordingly.', ThreatPerimeterRequired: true, Order: 2 },
        { RecommendedAction: 'Enrollment/Response Recommended', WDSStatus: 2, WDSAction: 'Enrollment is recommended for the threatened property.', ThreatPerimeterRequired: true, Order: 3 },
        { RecommendedAction: 'Program Responding', WDSStatus: 1, WDSAction: 'Program resources have been mobilized to the fire. Upon arrival, they will coordinate access with incident command before engaging in the primary mission for the threatened enrolled properties. If your policyholders in the threat perimeter require a gate code for WDS Response to serve the property, please enter the gate code in the Access window on the Properties tab in Fire Overview on the Dashboard.', ThreatPerimeterRequired: true, Order: 4 },
        { RecommendedAction: 'Program Resources On Incident', WDSStatus: 1, WDSAction: 'The program engine has coordinated access with incident command and will now engage in the primary mission for the threatened enrolled property/ies', ThreatPerimeterRequired: true, Order: 5 },
        { RecommendedAction: 'Program Resources Demobilized', WDSStatus: 3, WDSAction: 'Enrolled properties are no longer threatened. Program resources have checked out with incident command and demobilized from the incident. Should the need to re-engage arise, the program is prepared to respond back to threatened properties.', ThreatPerimeterRequired: false, Order: 6 },
        { RecommendedAction: 'New Fire - Status Pending', WDSStatus: 2, WDSAction: 'Note: This information is subject to change as WDS continues to investigate this newly discovered fire. Updates to follow.', ThreatPerimeterRequired: false, Order: 6 }],
    NoticeTypesRules: [
        { CurRecommendedAction: 'New Fire - Status Pending', NextRecommendedAction: 'No Immediate Threat' },
        { CurRecommendedAction: 'New Fire - Status Pending', NextRecommendedAction: 'Program Responding' },
        { CurRecommendedAction: 'New Fire - Status Pending', NextRecommendedAction: 'Potential Threat' },
        { CurRecommendedAction: 'New Fire - Status Pending', NextRecommendedAction: 'Program Resources On Incident' },
        { CurRecommendedAction: 'New Fire - Status Pending', NextRecommendedAction: 'Enrollment/Response Recommended' },
        { CurRecommendedAction: 'No Immediate Threat', NextRecommendedAction: 'No Immediate Threat' },
        { CurRecommendedAction: 'No Immediate Threat', NextRecommendedAction: 'Program Responding' },
        { CurRecommendedAction: 'No Immediate Threat', NextRecommendedAction: 'Potential Threat' },
        { CurRecommendedAction: 'No Immediate Threat', NextRecommendedAction: 'Program Resources On Incident' },
        { CurRecommendedAction: 'No Immediate Threat', NextRecommendedAction: 'Enrollment/Response Recommended' },
        { CurRecommendedAction: 'Potential Threat', NextRecommendedAction: 'Potential Threat' },
        { CurRecommendedAction: 'Potential Threat', NextRecommendedAction: 'Program Responding' },
        { CurRecommendedAction: 'Potential Threat', NextRecommendedAction: 'No Immediate Threat' },
        { CurRecommendedAction: 'Potential Threat', NextRecommendedAction: 'Program Resources On Incident' },
        { CurRecommendedAction: 'Potential Threat', NextRecommendedAction: 'Enrollment/Response Recommended' },
        { CurRecommendedAction: 'Program Responding', NextRecommendedAction: 'Program Responding' },
        { CurRecommendedAction: 'Program Responding', NextRecommendedAction: 'Program Resources On Incident' },
        { CurRecommendedAction: 'Program Responding', NextRecommendedAction: 'Program Resources Demobilized' },
        { CurRecommendedAction: 'Program Resources On Incident', NextRecommendedAction: 'Program Resources On Incident' },
        { CurRecommendedAction: 'Program Resources On Incident', NextRecommendedAction: 'Program Resources Demobilized' },
        { CurRecommendedAction: 'Program Resources Demobilized', NextRecommendedAction: 'Program Resources Demobilized' },
        { CurRecommendedAction: 'Program Resources Demobilized', NextRecommendedAction: 'No Immediate Threat' },
        { CurRecommendedAction: 'Program Resources Demobilized', NextRecommendedAction: 'Potential Threat' },
        { CurRecommendedAction: 'Program Resources Demobilized', NextRecommendedAction: 'Program Responding' },
        { CurRecommendedAction: 'Program Resources Demobilized', NextRecommendedAction: 'Program Resources On Incident' },
        { CurRecommendedAction: 'Enrollment/Response Recommended', NextRecommendedAction: 'Enrollment/Response Recommended' },
        { CurRecommendedAction: 'Enrollment/Response Recommended', NextRecommendedAction: 'No Immediate Threat' },
        { CurRecommendedAction: 'Enrollment/Response Recommended', NextRecommendedAction: 'Potential Threat' },
        { CurRecommendedAction: 'Enrollment/Response Recommended', NextRecommendedAction: 'Program Responding' },
        { CurRecommendedAction: 'Enrollment/Response Recommended', NextRecommendedAction: 'Program Resources On Incident' },
        { CurRecommendedAction: 'Enrollment/Response Recommended', NextRecommendedAction: 'Program Resources Demobilized' }
    ],
    Evacuations: ['Yes', 'No', 'Unknown'],
    HomesLost: ['There are no reports of structures lost.', 'There are reports of structures that have been lost.', 'There are reports of structures reported as damaged.', 'Unknown'],
    EngineStatus: [
        { Name: "Ready", NameInDB: "Ready", KpiFieldName: "numOfReady", Color: "#40A33A", LayerId: "engine-status-ready", AzureMapLayerId: "Engines_Ready", Icon: "EngineReadyIcon", BubbleLayerId: "Engines_Ready_Bubble", ClusterId: "Engines_Ready_Cluster", AllianceAzureMapLayerId: "Alliance_Engines_Ready", AllianceIcon: "AllianceEngineReadyIcon", AllianceBubbleLayerId: "Alliance_Engines_Ready_Bubble", AllianceClusterId: "Alliance_Engines_Ready_Cluster" },
        { Name: "Response", NameInDB: "Response", KpiFieldName: "numOfResponse", Color: "#C30044", LayerId: "engine-status-response", AzureMapLayerId: "Engines_Response", Icon: "EngineResponseIcon", BubbleLayerId: "Engines_Response_Bubble", ClusterId: "Engines_Response_Cluster", AllianceAzureMapLayerId: "Alliance_Engines_Response", AllianceIcon: "AllianceEngineResponseIcon", AllianceBubbleLayerId: "Alliance_Engines_Response_Bubble", AllianceClusterId: "Alliance_Engines_Response_Cluster" },
        { Name: "On Hold", NameInDB: "On Hold", KpiFieldName: "numOfOnHold", Color: "#898989", LayerId: "engine-status-on-hold", AzureMapLayerId: "Engines_OnHold", Icon: "EngineOnHoldIcon", BubbleLayerId: "Engines_OnHold_Bubble", ClusterId: "Engines_OnHold_Cluster", AllianceAzureMapLayerId: "Alliance_Engines_OnHold", AllianceIcon: "AllianceEngineOnHoldIcon", AllianceBubbleLayerId: "Alliance_Engines_OnHold_Bubble", AllianceClusterId: "Alliance_Engines_OnHold_Cluster" },
        { Name: "Not Staffed", NameInDB: "Not Staffed", KpiFieldName: "numOfNotStaffed", Color: "#4BBBE6", LayerId: "engine-status-in-service", AzureMapLayerId: "Engines_InService", Icon: "EngineInServiceIcon", BubbleLayerId: "Engines_InService_Bubble", ClusterId: "Engines_InService_Cluster", AllianceAzureMapLayerId: "Alliance_Engines_InService", AllianceIcon: "AllianceEngineInServiceIcon", AllianceBubbleLayerId: "Alliance_Engines_InService_Bubble", AllianceClusterId: "Alliance_Engines_InService_Cluster" },
        { Name: "Limited Service", NameInDB: "Limited Service", KpiFieldName: "numOfLimitedService", Color: "#465693", LayerId: "engine-status-limited-service", AzureMapLayerId: "Engines_LimitedService", Icon: "EngineLimitedServiceIcon", BubbleLayerId: "Engines_LimitedService_Bubble", ClusterId: "Engines_LimitedService_Cluster" },
        { Name: "Out Of Service", NameInDB: "Out of Service", KpiFieldName: "numOfOutOfService", Color: "#FED325", LayerId: "engine-status-out-of-service", AzureMapLayerId: "Engines_OutOfService", Icon: "EngineOutOfServiceIcon", BubbleLayerId: "Engines_OutOfService_Bubble", ClusterId: "Engines_OutOfService_Cluster" },
        { Name: "In Storage - Winterized", NameInDB: "In Storage", KpiFieldName: "numOfInStorage", Color: "#B09CCA", LayerId: "engine-status-in-storage-w", AzureMapLayerId: "Engines_InStorageWinter", Icon: "EngineInStorageWinterIcon", BubbleLayerId: "Engines_InStorageWinter_Bubble", ClusterId: "Engines_InStorageWinter_Cluster" },
        { Name: "Alliance", NameInDB: "Alliance", KpiFieldName: "numOfAlliance", Color: "#B09CCA", LayerId: "engine-status-alliance", AzureMapLayerId: "Engines_Alliance", Icon: "EngineAllianceIcon", BubbleLayerId: "Engines_Alliance_Bubble", ClusterId: "Engines_Alliance_Cluster" },
        { Name: "DOT Reset", NameInDB: "DOT Reset", KpiFieldName: "numOfDOTReset", Color: "#B09CCA", LayerId: "engine-status-DOTReset", AzureMapLayerId: "Engines_DOTReset", Icon: "EngineDOTResetIcon", BubbleLayerId: "Engines_DOT_Reset_Bubble", ClusterId: "Engines_DOT_Reset_Cluster" },
    ],

    SecurityGroups: { DutyOfficer: "DutyOfficer", DCA: "DataCaptureAnalyst" },
    WFNoticeTypes: [
        { RecommendedAction: 'No Immediate Threat', Abbr: 'NIT', Color: '#000000' },
        { RecommendedAction: 'Potential Threat', Abbr: 'PT', Color: '#EB7D3C' },
        { RecommendedAction: 'Enrollment/Response Recommended', Abbr: 'ER', Color: '#000000' },
        { RecommendedAction: 'Program Responding', Abbr: 'PR', Color: '#EB7D3C' },
        { RecommendedAction: 'Program Resources On Incident', Abbr: 'OI', Color: '#FF0000' },
        { RecommendedAction: 'Program Resources Demobilized', Abbr: 'DM', Color: '#C4C4C4' },
        { RecommendedAction: 'IP', Abbr: 'IP', Color: '#000000' }],

    WDSStatuses: {
        1: 'Dispatched',
        2: 'Non-Dispatched',
        3: 'Demobilized',
    },

    BlacklistedEmails: [
        'aol',
        'comcast',
        'centurylink',
        'gmail',
        'gmx',
        'hotmail',
        'icloud',
        'live',
        'mac',
        'msn',
        'orange',
        'outlook',
        'yahoo',
        'zoho',
        'wanadoo',
        'wildfire-defense'
    ]

}